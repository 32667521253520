export default {
  'en': {
    labels: {
      'en': 'English',
      'es': 'English',
    },
  },
  'es': {
    labels: {
      'en': 'Español',
      'es': 'Español',
    },
  },
};
