import * as React from 'react';
import './SiteHeader.scss';
import { useLocation } from '@reach/router';
import CityLanguageSelector from './CityLanguageSelector';
import Navbar from './Navbar';
import navigation from '../constants/navigation.js';
import languages from '../constants/languages';
import logo from '../images/lookup-evergreen-logo.svg';

function SiteHeaderCity() {
  const location = useLocation();
  const currentPathLanguage = location.pathname.split('/')[1];

  return (
    <>
    {/* <div className="top-bar columns">
        <div className='column is-one-quarter'><p>BGEA WEBSITES</p></div>
        <div className='column prayer-line'><p>Need Prayer? Call the 24/7 BGEA Prayer Line Today at 1-855-255-7729</p></div>gatsb
    </div> */}
      {location.pathname.split('/')[1] === 'en' ? (
        <>
        <div className="navigation">
        <header className="main-header">
          <div>
            <a href="/en/noco" className="logo" id="desktop-logo">
              <img src={logo} alt="logo" />
            </a>
            <div class="sec-center" id="desktop-dropdown"> 	
              <input class="dropdown" type="checkbox" id="dropdown" name="dropdown"/>
              <label class="for-dropdown" htmlFor="dropdown">Northern Colorado <i class="uil uil-arrow-down"></i></label>
              <div class="section-dropdown"> 
                <a href="/en/fountain-hills">Fountain Hills, AZ <i class="uil uil-arrow-right"></i></a>
                <a href='/en/'>General Information<i class="uil uil-arrow-right"></i></a>
              </div>
            </div>
          </div>
          {currentPathLanguage && navigation.primary[currentPathLanguage] && (
            <CityLanguageSelector />
          )}
        </header>
        <div>
          {currentPathLanguage && navigation.primary[currentPathLanguage] && (
            <Navbar items={navigation.primary[currentPathLanguage]}></Navbar>
          )}
        </div>
      </div>  
      </>   
        ) : (
        <>
          <div className="navigation" id="noco">
          <header className="main-header">
            <div>
              <a href="/es/noco" className="logo" id="desktop-logo">
                <img src={logo} alt="logo" />
              </a>
              <div class="sec-center" id="desktop-dropdown"> 	
                <input class="dropdown" type="checkbox" id="dropdown" name="dropdown"/>
                <label class="for-dropdown" htmlFor="dropdown">Northern Colorado <i class="uil uil-arrow-down"></i></label>
                <div class="section-dropdown"> 
                  <a href="/es/fountain-hills">FOUNTAIN HILLS, AZ <i class="uil uil-arrow-right"></i></a>
                  <a href='/es/'>Información general <i class="uil uil-arrow-right"></i></a>
                </div>
              </div>
            </div>
            {currentPathLanguage && navigation.primary[currentPathLanguage] && (
              <CityLanguageSelector />
            )}
          </header>
          <div>
            {currentPathLanguage && navigation.primary[currentPathLanguage] && (
              <Navbar items={navigation.primary[currentPathLanguage]}></Navbar>
            )}
          </div>
        </div>
        </>
        )}
    </>
  );
}

export default SiteHeaderCity;
